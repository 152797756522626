import { useState } from "react";
//import { useHistory } from "react-router-dom";
import classes from './Tests.module.css';

const CreateUser = () => {
  
  const [username, setUsername] = useState("");
  const [type, setType] = useState("");
  const [password, setPassword] = useState("");
  const [mastercd, setMastercd] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataFlask, setDataFlask] = useState("");

  //const [isPending, setIsPending] = useState(false);
  //const history = useHistory();

  const handleChange = (event) => {
    setType(event.target.value);
  };

	const handleSubmission = () => {
       
       const user = { username, password, type, mastercd };

    
        console.log(user);

		fetch(
			'https://flask-image-user-510500279206.us-central1.run.app/api/v1/register',
			{
				method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                  },
				body: JSON.stringify(user),
			}
		)
			.then((response) => response.json())
			.then((dataFlask) => {
				setDataFlask(dataFlask);
                setIsSubmitted(true);
			  })
			.catch((error) => {
				console.error('Error:', error);
			});
	  
	};

  return (
      <div>
      { localStorage["type"] === "Admin" ? 
      (
        <div className={classes.create}>
        <h1>
          Add an Institute or Admin or Student
        </h1>     
        <div>
          <label>Username:</label>
          <input
            type="text"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          </div>
          <div>
          <label>Password:</label>
          <input
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          </div>      
          <div>
          <label>Master Code:</label>
          <input
            type="text"
            required
            value={mastercd}
            onChange={(e) => setMastercd(e.target.value)}
            />
          </div>
          <div>
          <label>Type:</label>
          <select value={type} onChange={handleChange}>
            <option value="Select">--Select--</option>
            <option value="Student">Student</option>
            <option value="Admin">Admin</option>
            <option value="Institute">Institute</option>
          </select>
          </div>
          <div>
        {isSubmitted && (<p>{dataFlask["msg"]}</p>)}
        </div>
        <div>
          <button onClick={handleSubmission}>Submit to create Profile</button>
        </div>
        </div>
      ) : (
    <div className={classes.create}>
      <h1>
        Add a Student
      </h1>      
      <div>
        <label>Username:</label>
        <input
          type="text"
          required
          value={username}
          onChange={(e) => {setUsername(e.target.value); setMastercd(e.target.value.substring(0, 5)); setType("Student");}}
        />
        </div>
        <div>
        <label>Password:</label>
        <input
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        </div>      
        <div>
      {isSubmitted && (<p>{dataFlask["msg"]}</p>)}
      </div>
      <div>
        <button onClick={handleSubmission}>Submit to create Student Profile</button>
      </div>
      </div>)}
    </div> 
  );
};

export default CreateUser;