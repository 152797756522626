import { useState } from 'react';
import {  useEffect } from "react";
import './Result.css';

const ReportCreditsAvailable = () => {

  let url = 'https://flask-image-credits-510500279206.us-central1.run.app/creditsUserName?cd=' + localStorage["username"];

	const [dataFlask, setDataFlask] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(()=>{
        fetch(
            url
    )
      .then((response) => response.json())
      .then((dataFlask) => {
        setDataFlask(dataFlask);
        setIsLoaded(true);
        })
      .catch((error) => {
        console.error('Error:', error);
      })}, [url]);


  return (
      <div>
    <div className="content"><h2>Remaining Exam Credits</h2></div>
    <div className="Result">
      {!isLoaded && <div><b>No Data has arrived yet..</b></div>}
      <h2>{isLoaded && dataFlask["credits"]}</h2>
    </div>
    </div>
  );

}

export default ReportCreditsAvailable;