import { Switch, Route, Redirect } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from './auth-context';
import Layout from './Layout';
import HomePage from './HomePage';
import AuthPage from './AuthPage';
import Tests from './Tests';
import CreateTest from './CreateTest';
import ViewTests from './ViewTests';
import TakeTest from './TakeTest';
import Result from './Result';
import CreateUser from './CreateUser';
import UserProfile from './UserProfile';
import CreditsUpsert from './CreditsUpsert';
// import TakeTestBkp from './TakeTestBkp';

function App() {

  const authCtx = useContext(AuthContext)

  return (
    <Layout>
      <Switch>
        <Route path='/' exact>
          <HomePage />
        </Route>
        
        {!authCtx.isLoggedIn && (<Route path='/auth'>
          <AuthPage />
        </Route>)}

        <Route path='/tests'>
        {authCtx.isLoggedIn && <Tests />}
        {!authCtx.isLoggedIn && <Redirect to='auth' />}
        </Route>

        <Route path='/profile'>
        {authCtx.isLoggedIn && <UserProfile />}
        {!authCtx.isLoggedIn && <Redirect to='auth' />}
        </Route>

        <Route path='/createTest'>
        {authCtx.isLoggedIn && <CreateTest />}
        {!authCtx.isLoggedIn && <Redirect to='auth' />}
        </Route>

        <Route path='/viewTests'>
        {authCtx.isLoggedIn && <ViewTests />}
        {!authCtx.isLoggedIn && <Redirect to='auth' />}
        </Route>

        <Route path="/exams/:id">
        {authCtx.isLoggedIn && <TakeTest />}
        {!authCtx.isLoggedIn && <Redirect to='auth' />}
        </Route>

        <Route path='/results'>
        {authCtx.isLoggedIn && <Result />}
        {!authCtx.isLoggedIn && <Redirect to='auth' />}
        </Route>

        <Route path='/addCredit'>
        {authCtx.isLoggedIn && localStorage["mastercd"] === "admin" && <CreditsUpsert />}
        </Route>

        <Route path='/createUser'>
        {authCtx.isLoggedIn && <CreateUser />}
        {!authCtx.isLoggedIn && <Redirect to='auth' />}
        </Route>

        <Route path='*'>
          <Redirect to='/' />
        </Route>
        
      </Switch>
    </Layout>
  );
}

export default App;
