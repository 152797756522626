import './Result.css';
import ResultDetails from "./ResultDetails";
import * as React from 'react';

const Result = () => {

  return (
    <div className="content">
      <div className="Result">
      <ResultDetails />
    </div>
    </div>
  );

}

export default Result;