import { Link } from 'react-router-dom';

import classes from './Tests.module.css';

const Tests = () => {

  //console.log(localStorage["type"]);
  //console.log(localStorage["username"]);
  //console.log(localStorage["mastercd"]);

  return (
    <div>
    { localStorage["type"] !== "Student" ?
    (
    <div className={classes.content}>
            <h1>Online Tests</h1>
      <div></div>
        <ul>
                    <div className={classes.blog}>
                    <Link to='/createTest'>Create Test</Link>
                    </div>       
        </ul>
        <ul>
                    <div className={classes.blog}>
                    <Link to='/viewTests'>View Tests</Link>
                    </div>    
        </ul>
        <ul>    
        </ul>
    </div>
    ) : (
          <div className={classes.content}>
            <h1>Online Tests</h1>
      <div></div>
        <ul>
                    <div className={classes.blog}>
                    <Link to='/viewTests'>View Tests</Link>
                    </div>    
        </ul>
        <ul>    
        </ul>
    </div>
    
  )}
  </div>);
};

export default Tests;