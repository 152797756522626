import { useRef, useState, useContext } from 'react';
import { useHistory} from 'react-router-dom';


import AuthContext from './auth-context';

import classes from './AuthForm.module.css';

const AuthForm = () => {
  const history = useHistory();
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  const authCtx = useContext(AuthContext)

  //const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  /*const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };*/

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredUsername = usernameInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    // Optional Add Validation

    localStorage.setItem('username', enteredUsername);

    setIsLoading(true);

    let url;

      url = 'https://flask-image-user-510500279206.us-central1.run.app/api/v1/login';

    fetch(
      url,
    {
      method: 'POST',
      body: JSON.stringify({
        username: enteredUsername,
        password: enteredPassword,
        returnSecureToken: true
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    ).then((res) => {
      setIsLoading(false);
      if (res.ok) {
        return res.json();
      }
      else {
        return res.json().then(data => {
          let errorMessage = 'Authentication Failed';
          //if (data && data.error && data.error.message) {
          //  errorMessage = data.error.message
          //}
          throw new Error(errorMessage);
        });
      }
    }).then(data => {
      //console.log(data);
      const expirationTime = new Date(new Date().getTime() + (+data.expiresIn * 1000));
      authCtx.login(data.idToken, expirationTime.toISOString(), data.username, data.mastercd, data.type, data.name);
      //console.log(enteredEmail);
      history.replace('/');
    })
    .catch((err => {
      alert(err.message);
    }))
  };

  return (
    <div>
    <section className={classes.auth}>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor='username'>Username</label>
          <input type='text' id='username' required ref={usernameInputRef}/>
        </div>
        <div className={classes.control}>
          <label htmlFor='password'>Password</label>
          <input type='password' id='password' required ref={passwordInputRef}/>
        </div>
        <div className={classes.actions}>
          <button>Login</button>
          {isLoading && <p>Sending Request....</p>}
        </div>
      </form>
    </section>
    </div>
  );
};

export default AuthForm;
