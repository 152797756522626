//import {  useEffect } from "react";
import { useState } from 'react';
import {  useEffect } from "react";
import { Link } from "react-router-dom";
import classes from './Tests.module.css';

const ViewTests = () => {

	const [dataFlask, setDataFlask] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(()=>{
        fetch(
            'https://flask-image-fetch-exam-510500279206.us-central1.run.app/fetchExams?cd=' + localStorage["mastercd"]
    )
      .then((response) => response.json())
      .then((dataFlask) => {
        setDataFlask(dataFlask);
        setIsLoaded(true);
        })
      .catch((error) => {
        console.error('Error:', error);
      })}, []);


  const loadedExams = [];

  if (isLoaded === true) {

  for (const key in dataFlask["value"]) {
    //console.log(key);
    loadedExams.push({
      id: dataFlask["value"][key]["_id"]["$oid"],
      subject: dataFlask["value"][key].subject,
      datetime: dataFlask["value"][key].datetime,
      duration: dataFlask["value"][key].duration,
      credits: dataFlask["value"][key].credits,
    });
  }

}

  return (
                <div className={classes.content}>
                    <p>Your Tests will appear here</p>
                        {loadedExams.map((exam) => (
                            <div className={classes.blog} key={exam.id}>
                              <Link to={{ pathname: `/exams/${exam.id}`, state: { d: `${exam.duration}`, e: `${exam.credits}` } }}>
                                <h2>{exam.subject}</h2>
                                <h3>Exam Date: {exam.datetime}</h3>
                                <h4>Duration: {exam.duration} mins</h4>
                                <h4>Credits Required: {exam.credits}</h4>
                              </Link>
                            </div>
                          ))}
    </div>
  );
};

export default ViewTests;