import classes from './StartingPageContent.module.css';
import topics from './images/topics.png';

const StartingPageContent = () => {
  
  return (
  <div className={classes.content}>
    <div>
      <h1>Online Assessments and Evaluation Platform</h1>
    </div>
    <div>
      <img src={topics} alt="Front page"/>
    </div>
  </div>
  );
};

export default StartingPageContent;
