import { useState } from 'react';
import {  useEffect } from "react";
import './Result.css';
import BarGraphSubjectWise from './BarGraphSubjectWise';
import BarGraphStudentWise from './BarGraphStudentWise';

const ResultDetails = () => {

  let url;

  const [subject, setSubject] = useState("");
  const [userName, setUserName] = useState("");

  const handleChange = (event) => {
    setSubject(event.target.value);
  };

  const handleChangeName = (event) => {
    setUserName(event.target.value);
  };

  const subjectsArray = [];
  const studentsArray = [];

if ((localStorage["type"] === "Institute") || (localStorage["type"] === "Admin")) {

      url = 'https://flask-image-result-510500279206.us-central1.run.app/resultsMasterCd?cd=' + localStorage["mastercd"]

  }

  else {

      url = 'https://flask-image-result-510500279206.us-central1.run.app/resultsUserName?cd=' + localStorage["username"]

  }

	const [dataFlask, setDataFlask] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);


    useEffect(()=>{
        fetch(
            url
    )
      .then((response) => response.json())
      .then((dataFlask) => {
        setDataFlask(dataFlask);
        setIsLoaded(true);
        })
      .catch((error) => {
        console.error('Error:', error);
      })}, [url]);

  const loadedData = [];

  //console.log(scores);

  if (isLoaded === true) {

  for (const key in dataFlask["scores"]) {
    //console.log(key);
    loadedData.push({
      id: dataFlask["scores"][key]["_id"]["$oid"],
      date: dataFlask["scores"][key].date,
      mastercd: dataFlask["scores"][key].mastercd,
      subject: dataFlask["scores"][key].subject,
      username: dataFlask["scores"][key].username,
      score: dataFlask["scores"][key].score
    });
  }

  for (let i = 0; i < loadedData.length; i++) {
    if (!subjectsArray.includes(loadedData[i].subject)) {
    subjectsArray.push(loadedData[i].subject);
    }
  }

  for (let i = 0; i < loadedData.length; i++) {
    if (!studentsArray.includes(loadedData[i].username)) {
      studentsArray.push(loadedData[i].username);
    }
  }

  //console.log(subjectsArray);
  //console.log(subject);

}

  
  return (
    <div>
      <div>
    <h1>Complete Result</h1>
      {!isLoaded && <div>Loading...</div>}
      {isLoaded && 
      <div className="Result"><table>
        <tr>
          <th>Date</th>
          <th>Master Code</th>
          <th>Subject</th>
          <th>User Name</th>
          <th>Score</th>
        </tr>
        {loadedData.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.date}</td>
              <td>{val.mastercd}</td>
              <td>{val.subject}</td>
              <td>{val.username}</td>
              <td>{val.score}</td>
            </tr>
          )
        })}
      </table></div>}
      </div>
      <div>
        <h2>Analyse Subject wise Result</h2>
      </div>
      <div>
      {!isLoaded && <div>Loading...</div>}
      {isLoaded && <div>
            Select a Subject:
            <select value={subject} onChange={handleChange}>
              {subjectsArray.map(item => (
                  <option key={item} value={item}>{item}</option>
              ))}
            </select>
            <BarGraphSubjectWise data={loadedData} filter = {subject} />
        </div>}
      </div>
      {((localStorage["type"] === "Institute") || (localStorage["type"] === "Admin")) && <div>
      <div>
        <h2>Analyse Student wise Result</h2>
      </div>
      <div>
      {!isLoaded && <div>Loading...</div>}
      {isLoaded && <div>
            Select a Student:
            <select value={userName} onChange={handleChangeName}>
              {studentsArray.map(item => (
                  <option key={item} value={item}>{item}</option>
              ))}
            </select>
            <BarGraphStudentWise data={loadedData} filter = {userName} />
        </div>}
      </div>
      </div>}
    </div>
  );

}

export default ResultDetails;