import {  useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import classes from './Tests.module.css';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TakeTest = () => {

  const { id } = useParams();

	const history = useHistory();

  let data = useLocation();

  const [seconds, setSeconds] = useState(data.state["d"]* 60);
  
  let credits = data.state["e"]* 1;

	const [dataFlask, setDataFlask] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedCredit, setIsLoadedCredit] = useState(false);

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
  //const [questions, setQuestions] = useState("");

  const [resp, setResp] = useState("");

  useEffect(()=>{
		fetch(
			"https://flask-image-credits-510500279206.us-central1.run.app/credit_transaction",
			{
			  method: "POST",
			  headers: {
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify({"credits_user_name": localStorage["username"], "credits_master_cd": localStorage["mastercd"], "exam_credits": credits}),
			}
		  ).then((response) => response.json())
      .then((resp) => {
        setResp(resp["response"]);
        setIsLoadedCredit(true);
        })}, [credits]);

  useEffect(()=>{
    fetch(
        'https://flask-image-fetch-exam-510500279206.us-central1.run.app/fetchOneExam?examid=' + id
)
  .then((response) => response.json())
  .then((dataFlask) => {
    setDataFlask(dataFlask);
    setIsLoaded(true);
    })
  .catch((error) => {
    console.error('Error:', error);
  })}, [id]);

  const loadedExam = [];

  let questions = [];
  let subject;
  //let duration;

  if (isLoaded === true) {

  for (const key in dataFlask["value"]) {
    //console.log(key);
    loadedExam.push({
      id: dataFlask["value"][key]["_id"]["$oid"],
      subject: dataFlask["value"][key].subject,
      content: dataFlask["value"][key].qna,
      duration: dataFlask["value"][key].duration,
    });
    
  }

  questions = loadedExam[0]["content"];
  subject = loadedExam[0]["subject"];
  //duration = Number(loadedExam[0]["duration"]);
  

  //console.log(duration);

  }

  

  const current = new Date();

	const handleAnswerOptionClick = (isCorrect) => {
		if (isCorrect) {
			setScore(score + 1);
		}
		
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
	};

	const handleSubmitClick = () => {
		fetch(
			"https://flask-image-result-510500279206.us-central1.run.app/scoreInput",
			{
			  method: "POST",
			  headers: {
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify({"mastercd":localStorage["mastercd"], "subject":subject, "username":localStorage["username"], "score":score, "date": `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`}),
			}
		  ).then(() => {
			console.log("new score added!");
			history.push("/tests");
		  });
	};

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      handleSubmitClick();
    }
  });

  return (
    <div>
      { 
      !isLoadedCredit ? (
        <div className={classes.content}>
        <p>Checking Available Credits....</p>
      </div>
      ) : (
        <div> 
      { 
        resp === 'no' ? (
        <div className={classes.content}>
        <h2><p>Insufficient Credits</p></h2>
      </div>
      ) : (
      <div>
      <div className={classes.timertext}><b>Time Left: {Math.floor(seconds / 60)}:{seconds - Math.floor(seconds / 60) * 60}</b></div>
      {
      !isLoaded ? (
      <div>
        <p>Loading....</p>
      </div>
      ) : (
        <section className={classes.content}>
      <div>
        <h2><p>Subject: {subject}</p></h2>
      </div>
          {
          showScore ? (
            <div>
            <div className={classes.app}>
              <h2>You scored {score} out of {questions.length}</h2>
            </div>
            <div className={classes.create}>
              <button onClick={handleSubmitClick}>Click Here to Submit Your Exam</button>
            </div>
            </div>
          ) : (
            <div>
              <div>
              <div className={classes.app}>
                <div>
                  <h2>Question {currentQuestion + 1}/{questions.length}</h2>
                </div>
                </div>
                <div className={classes.app}>
                <div><h2>{questions[currentQuestion]["questionText"]}</h2></div>
                </div>
              </div>
              <div className={classes.app_new}>
              <div className={classes.answersection}>
                {questions[currentQuestion]["answerOptions"].map((answerOption) => (
                  <button onClick={() => handleAnswerOptionClick(answerOption["isCorrect"])}><b>{answerOption["answerText"]}</b></button>
                ))}
              </div>
              </div>
            </div>
          )}
        </section> )}
               </div> )} </div>)}
    </div>
  );
};

export default TakeTest;
