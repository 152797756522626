import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

const BarGraphStudentWise = ( { data, filter } ) => {

    let subjectArray = [];
    let scoreArray = [];

    for (let i = 0; i < data.length; i++) {
    
      //console.log(data[i].username);
      //console.log(filter);
      if (data[i].username === filter) {
      subjectArray.push(data[i].subject);
      scoreArray.push(data[i].score);
      }
    }

    const state = {
      labels: subjectArray,
      datasets: [
        {
          label: "Results",
          backgroundColor: "rgba(75,100,192,1)",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 2,
          data: scoreArray,
        },
      ],
    };
    return (
      <div>
        <Bar
          data={state}
          options={{
            title: {
              display: true,
              text: "Results",
              fontSize: 20,
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      </div>
    );
  };

export default BarGraphStudentWise;
