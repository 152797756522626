import { useState } from "react";
//import { useHistory } from "react-router-dom";
import classes from './Tests.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 

const CreateTest = () => {
  const [subject, setSubject] = useState("");
  const [duration, setDuration] = useState(0);
  const [credits, setCredits] = useState(0);
  const [datetime, setDateTime] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataFlask, setDataFlask] = useState("");
  //const [marksPerQuestion, setMarksPerQuestion] = useState(0);
  //const [isPending, setIsPending] = useState(false);
  //const history = useHistory();

  /*const handleChange = (event) => {
    setMarksPerQuestion(event.target.value);
  };*/

  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

  const handleChangeDuration = (event) => {
    setDuration(event.target.value);
  };

  const handleChangeCredits = (event) => {
    setCredits(event.target.value);
  };

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);
		formData.append('Subject', subject);
    formData.append('mastercd', localStorage["username"]);
    formData.append('datetime', datetime);
    formData.append('duration', duration);
    formData.append('credits', credits);
    //formData.append('MarksPerQuestion', marksPerQuestion);


		fetch(
			'https://flask-image-excel-upload-510500279206.us-central1.run.app/uploadExcel',
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((dataFlask) => {
				setDataFlask(dataFlask);
			  })
			.catch((error) => {
				console.error('Error:', error);
			});

      setIsSubmitted(true);
	  
	};

  return (
    <div className={classes.create}>
      <h1>
        Add a Test
      </h1>
      <div>
        <label>Subject:</label>
        <input
          type="text"
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div>
        <label>Date and Time of the Test to be taken:</label>
        <DatePicker dateFormat="dd/MM/yyyy h:mm aa" selected={datetime} onChange={(datetime) => setDateTime(datetime)} showTimeSelect />
      </div>
      <div className="select">
      <label>Duration of Test (Mins):</label>
      <select value={duration} onChange={handleChangeDuration}>
        <option value="--select--">--select--</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="30">30</option>
      </select>
      </div>
      <div className="select">
      <label>Credits Required:</label>
      <select value={credits} onChange={handleChangeCredits}>
        <option value="--select--">--select--</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="30">30</option>
      </select>
      </div>
      <div>
      <label>Upload Questions using .xlsx File (Maximum 30 Questions):</label>
        <input type="file" name="file" onChange={changeHandler} />
            { isSelected ? (
              <div>
                <p>Filename: {selectedFile.name}</p>
                <p>Filetype: {selectedFile.type}</p>
                <p>Size in bytes: {selectedFile.size}</p>
                <p>
                  lastModifiedDate:{' '}
                  {selectedFile.lastModifiedDate.toLocaleDateString()}
                </p>
              {isSubmitted ? 
					(
            <p>{dataFlask["value"]}</p>
					) : 
					( <div></div> )}
              </div>
                         ) : (
				<p>Select a file to show details</p>
			)}
    </div>
      <div>
        <button onClick={handleSubmission}>Submit to create Test</button>
      </div>
    </div>
  );
};

export default CreateTest;
