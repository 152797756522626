import { useState } from "react";
//import { useHistory } from "react-router-dom";
import classes from './Tests.module.css';

const CreditsUpsert = () => {
  
  const [username, setUsername] = useState("");
  const [credits, setCredits] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataFlask, setDataFlask] = useState("");

  //const [isPending, setIsPending] = useState(false);
  //const history = useHistory();

	const handleSubmission = () => {

		fetch(
			'https://flask-image-credits-510500279206.us-central1.run.app/credit_upsert',
			{
				method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                  },
				body: JSON.stringify({"credits_user_name": username, "exam_credits": credits}),
			}
		)
			.then((response) => response.json())
			.then((dataFlask) => {
				setDataFlask(dataFlask);
                setIsSubmitted(true);
			  })
			.catch((error) => {
				console.error('Error:', error);
			});
	  
	};

  return (
    <div className={classes.create}>
      <h1>
        Provide Credits to Student / Institute
      </h1>
      <div>
          <label>Credit User ID:</label>
          <input
            type="text"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          </div>           
      <div>
        <label>Credits to be added:</label>
        <input
          type="number"
          required
          value={credits}
          onChange={(e) => setCredits(e.target.value)}
        />
        </div>  
        <div>
      {isSubmitted && (<p>{dataFlask["value"]}</p>)}
      </div>
      <div>
        <button onClick={handleSubmission}>Submit to add credits</button>
      </div>
    </div> 
  );
};

export default CreditsUpsert;