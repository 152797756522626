import classes from './UserProfile.module.css';
//import ProfileForm from './ProfileForm';
import Analytics from './Analytics';
import ReportCreditsAvailable from './ReportCreditsAvailable';

const UserProfile = () => {
  
  return (
    <div>
    <div className={classes.profile}>
      <ReportCreditsAvailable />
    </div>
    <div>{ localStorage["type"] !== "Student" &&    
    <section className={classes.profile}>
      <Analytics />
    </section>}
    </div>
    </div>
  );
};

export default UserProfile;
