import { useState } from 'react';
import {  useEffect } from "react";
import './Result.css';

const Analytics = () => {

  let url;

if (localStorage["type"] === "Institute") {

      url = 'https://flask-image-result-510500279206.us-central1.run.app/resultsMasterCd?cd=' + localStorage["mastercd"]

  }

else {

      url = 'https://flask-image-result-510500279206.us-central1.run.app/resultsUserName?cd=' + localStorage["username"]

  }

	  const [dataFlask, setDataFlask] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(()=>{
        fetch(
            url
    )
      .then((response) => response.json())
      .then((dataFlask) => {
        setDataFlask(dataFlask);
        setIsLoaded(true);
        })
      .catch((error) => {
        console.error('Error:', error);
      })}, [url]);

  const loadedData = [];

  //console.log(scores);

  if (isLoaded === true) {

  for (const key in dataFlask["scores"]) {
    //console.log(key);
    loadedData.push({
      date: dataFlask["scores"][key].date,
      username: dataFlask["scores"][key].username,
      mastercd: dataFlask["scores"][key].mastercd,
      subject: dataFlask["scores"][key].subject,
    });
  }

  }

  return (
      <div>
    <div className="content"><h2>Submitted Tests</h2></div>
    <div className="Result">
      {!isLoaded && <div><b>Loading...No Data has arrived yet...</b></div>}
      {isLoaded && <table>
        <tr>
          <th>Date</th>
          <th>User Name</th>
          <th>Master Code</th>
          <th>Subject</th>
        </tr>
        {loadedData.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.date}</td>
              <td>{val.username}</td>
              <td>{val.mastercd}</td>
              <td>{val.subject}</td>
            </tr>
          )
        })}
      </table>}
    </div>
    </div>
  );

}

export default Analytics;