import { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from './auth-context';
import classes from './MainNavigation.module.css';

const MainNavigation = () => {

  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <header className={classes.header}>
      <Link to='/'>
        <div className={classes.logo}>EmPower YourSelf</div>
      </Link>
      <nav>
        <ul>
          {!isLoggedIn && (
          <li>
            <Link to='/auth'>Login Here</Link>
          </li>
          )}
          {isLoggedIn && (
          <li>
            <Link to='/results'>Results</Link>
          </li>
          )}           
          {isLoggedIn && (
          <li>
            <Link to='/tests'>Tests</Link>
          </li>
          )}     
          {isLoggedIn && localStorage["type"] !== "Student" && (
          <li>
            <Link to='/createUser'>Create User</Link>
          </li>
          )}
          {isLoggedIn && (
          <li>
            <Link to='/profile'>Profile</Link>
          </li>
          )}                              
          {isLoggedIn && (
          <li>
            <button onClick={logoutHandler}>Logout</button>
          </li>
          )}
          {isLoggedIn && (
          <li>
            user: <b>{localStorage["username"]}</b>
          </li>
          )}            
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
